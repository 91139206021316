.grid-form .item{
    display: flex;
    flex-direction: column;
}
.title{
    text-align: center;
    margin: 20px 0 20px 0!important;
}
.fb-button{
    margin: 0 1rem 0 1rem!important;
}
.reponse-message{
    text-align: center;
}
.reponse-message p{
    text-align: center;
    background: #d3a188;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    color: #000;
    font-weight: 500;
}
.twilio-dataTable{
    margin-top: 50px;
}
.filter-notes{
    margin: 5px 0 0 0px;
}